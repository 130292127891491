<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row" id="case">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="form-inline navbar-search">
                  <div class="input-group">
                    <input name="title" class="form-control bg-light border-0 small" placeholder="Search..."
                      aria-label="Search" aria-describedby="basic-addon2" value="" v-model="filter"
                      v-on:keyup="search" />
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="searchFor">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="all-tabs">
                  <div :class="activeTab == 'all' ? ' tab-options' : ''"
                    @click.prevent="fetchData('all'); currentPage = 1" class="mx-2 opt">
                    All
                  </div>
                  |
                  <div :class="activeTab == 'onboard' ? ' tab-options' : ''" @click.prevent="
                    fetchData('onboard');
                  currentPage = 1;
                  " class="mx-2 opt text-success">
                    Onboarded
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table striped bordered :items="tableData.data" :fields="fields" :sort-by.sync="sortBy" :per-page="0"
                :current-page="currentPage" :sort-desc.sync="sortDesc" :filter-included-fields="filterOn"
                @filtered="onFiltered" responsive="sm" class="table-bordered table-hover" :key="key">
                <template v-slot:cell(doctor_onboard_status)="row">
                  <span class="badge badge-success p-2"
                    v-if="row.item?.member_extra_detail?.is_onboard == 1">Onboarded</span>
                  <b-button class="btn btn-sm btn-secondary" variant="secondary"
                    v-if="row.item?.member_extra_detail?.is_onboard == 0 || !row.item?.member_extra_detail"
                    @click="onBoardModal(row.item)">Onboard
                  </b-button>
                </template>
                <template v-slot:cell(doctor_onboard_status)="row">
                  <span class="badge badge-success p-2"
                    v-if="row.item?.member_extra_detail?.is_onboard == 1">Onboarded</span>
                  <b-button class="btn btn-sm btn-secondary" variant="secondary"
                    v-if="row.item?.member_extra_detail?.is_onboard == 0 || !row.item?.member_extra_detail"
                    @click="onBoardModal(row.item)">Onboard
                  </b-button>
                </template>
                <template v-slot:cell(medisage_commission)="row">
                  <span> {{ Number(row.item?.member_extra_detail?.medisage_commission ?? 0.00).toFixed(2) }}</span>
                </template>
                <template v-slot:cell(medisage_commission_type)="row">
                  <span> {{ row.item?.member_extra_detail?.medisage_commission_type ?? "fixed" }}</span>
                </template>
                <template v-slot:cell(name)="row">
                  <template v-if="
                    (row.item.fname != null && row.item.fname != '') ||
                    (row.item.lname != null && row.item.lname != '')
                  ">
                    <span class="email-word-break">{{ row.item.fname }} {{ row.item.lname }}</span>
                  </template>
                  <template v-else>
                    <span>---</span>
                  </template>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination :key="key" v-model="currentPage" first-number last-number
                      :total-rows="tableData.total" :total-pages="tableData.total_pages" :per-page="tableData.per_page">
                    </b-pagination>
                  </ul>
                </div>
                <div class="form-inline navbar-search">
                  <span class="mr-2">Go to a Particular Paginated Page :</span>
                  <div class="input-group">
                    <b-form-input id="case" v-model="pageNo" placeholder="Enter Page No" class="in-width">
                    </b-form-input>
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="filterPage">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <b-modal id="showOnBoardModal" ref="modal" title="Onboard Doctor" @ok="handleOnboardDoctorSubmit"
          @cancel="clearOnBoardModal" v-model="showOnBoardModal" no-close-on-backdrop centered hide-header-close
          ok-title="Ok. Continue" cancel-title="No">
          <template #modal-title>
            <i class="fa fa-user-md mr-1"></i> Onboard Doctor
          </template>
          <p>Please note :-</p>
          <ul>
            <li>A processed doctor will be activated with the basic plan in WOW.</li>
            <li> If an existing plan is active in WOW, it will not be updated.</li>
            <li>After onboarding, clinic details and doctor’s availability must be added.</li>
          </ul>
          <div>
            <b-form-group label="Medisage Commission :-">
              <b-form-radio-group v-model="medisageType">
                <b-form-radio value="percentage">Percentage</b-form-radio>
                <b-form-radio value="fixed">Fixed</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group :label="inputLabel" label-for="medisageInput">
              <b-input-group style="max-width: 180px;">
                <b-form-input id="medisageInput" v-model="form.medisage_cut"
                  :type="medisageType === 'percentage' ? 'number' : 'text'" placeholder="Enter value"
                  :min="medisageType === 'percentage' ? 0 : null" :max="medisageType === 'percentage' ? 100 : null"
                  required></b-form-input>
                <b-input-group-append v-if="medisageType === 'percentage'">
                  <b-input-group-text>%</b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>

<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import caseMixin from "../../../mixins/ModuleJs/doctor-onboarding";

export default {
  data() {
    return {
      title: "Doctor Onboarding",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
      filter: null,
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filterOn: [],
      key: 0,
      currentPage: 1,
      activeTab: "all",
      params: "",
    };
  },
  mixins: [MixinRequest, caseMixin],
  components: {
    Layout,
    PageHeader,
  },
};
</script>
