import { doctor_onboarding } from "../../js/path";

export default {
  data() {
    return {
      status: false,
      table_header: [],
      case_id: null,
      images: [],
      videos: [],
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "name",
          label: "Member Name",
        },
        {
          key: "city",
        },
        {
          key: "email",
        },
        {
          key: "doctor_onboard_status",
          label: "Onboard Status",
        },
        {
          key: "medisage_commission",
        },
        {
          key: "medisage_commission_type",
        },
      ],
      filter: null,
      pageNo: null,
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filterOn: [],
      currentPage: 1,
      showOnBoardModal: false,
      oldForm: {},
      form: {
        member_id: "",
        medisage_cut: "",
      },
      medisageType: "percentage",
      modalItem: null,
      memberList: [],
    };
  },
  methods: {
    isEmpty(value) {
      return Array.isArray(value) && value.length === 0;
    },
    removeOption(index, key) {
      if (index == 0) return;
      this.form.addMore[key].option.splice(index, 1);
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.currentPage = 1;
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      }
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      } else {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onBoardModal(item) {
      this.form.member_id = item.id;
      this.form.medisage_cut = item.medisage_cut ?? "";
      this.showOnBoardModal = true;
    },
    clearOnBoardModal() {
      this.form.member_id = "";
      this.form.medisage_cut = "";
      this.showOnBoardModal = false;
    },
    checkPercentageValidity() {
      if (this.form.medisage_cut == "") {
        this.submitted = true;
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please enter commission value",
        });
        return false;
      }
      return true;
    },
    async fetchData(txt) {
      this.activeTab = txt;
      let url = `${doctor_onboarding.doctorOnboarding}?filter=${txt}&page=${
        this.currentPage ?? 1
      }`;
      if (this.filter) {
        url += `&search=${this.filter}`;
      }
      this.getUrl();
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          this.tableData = data.response;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data?.message || "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },

    async handleOnboardDoctorSubmit(bvModalEvt) {
      bvModalEvt.preventDefault();
      // validation
      const validate = this.checkPercentageValidity();
      if (!validate) {
        return;
      } else {
        let url = `${doctor_onboarding.doctorOnboarding}/${this.form.member_id}`;
        let dataAppend = new FormData();
        dataAppend.append("_method", "put");
        dataAppend.append("medisage_commission", this.form.medisage_cut);
        dataAppend.append("medisage_commission_type", this.medisageType);
        this.$store.commit("loader/updateStatus", true);
        try {
          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data?.message ?? "Doctor onboarded successfully",
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
      }
      this.showOnBoardModal = false;
      this.medisageType = "percentage";
      this.fetchData("all");
    },
    deleteRow(index) {
      this.form.addMore.splice(index, 1);
    },
    getUrl() {
      var url = new URL(window.location);
      if (this.activeTab == null || this.activeTab == "") {
        this.activeTab = "all";
      }
      url.searchParams.has("filter")
        ? url.searchParams.set("filter", this.activeTab)
        : url.searchParams.append("filter", this.activeTab);
      if (this.filter != null && this.filter != "") {
        url.searchParams.has("search")
          ? url.searchParams.set("search", this.filter)
          : url.searchParams.append("search", this.filter);
      } else {
        url.searchParams.delete("search");
      }
      if (this.currentPage != null && this.currentPage != "") {
        url.searchParams.has("page")
          ? url.searchParams.set("page", this.currentPage)
          : url.searchParams.append("page", this.currentPage);
      } else {
        url.searchParams.set("page", 1);
      }
      url.search = url.searchParams;
      url = url.toString();
      history.pushState({}, null, url);
    },

    tabActive() {},
  },
  watch: {
    status(v) {
      if (v == 1) {
        this.fetchAllCommunity();
      } else {
        if (this.$route.name == "add-case") {
          this.form.community_selected = [];
        } else if (this.$route.name == "edit-case") {
          this.form.community_selected = this.oldForm.community_selected;
        }
      }
    },
    currentPage: {
      handler: function (value) {
        this.params = `&page=${value}`;
        this.fetchData(this.activeTab);
        this.pageNo = this.currentPage;
      },
    },
    activeTab(v) {
      if (v) this.tabActive();
    },
  },
  mounted() {
    if (
      this.$route.name == "add-doctor-onboarding" ||
      this.$route.name == "edit-doctor-onboarding"
    ) {
      if (this.$route.name == "edit-doctor-onboarding") {
        this.fetchDoctor(this.$route.params.id);
      }
    } else {
      this.activeTab = "all";
      if (this.$route.query.filter) {
        this.activeTab = this.$route.query.filter;
      }
      if (this.$route.query.search) {
        this.filter = this.$route.query.search;
      }
      if (this.$route.query.page && this.$route.query.page != 1) {
        this.currentPage = parseInt(this.$route.query.page);
      } else {
        this.fetchData(this.activeTab);
      }
    }
    this.fields.map((item) => {
      this.table_header.push(item.key);
    });
    this.tabActive();
  },
};
